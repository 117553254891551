import { CSS_VARIABLES } from '@doltech/utils/lib/css-style.variables';
import cl from 'classnames';
import * as React from 'react';
import styled from 'styled-components';

import { BaseButton, BaseButtonProps } from '../BaseButton/BaseButton';

const BUTTON_ICON_CSS_VARIABLES = CSS_VARIABLES.BUTTONS_ICON_ONLY;

const PRIMARY_STYLES = `
  background-color: var(${BUTTON_ICON_CSS_VARIABLES.PRIMARY.BG_COLOR});
  color: var(${BUTTON_ICON_CSS_VARIABLES.PRIMARY.TEXT_COLOR});

  &:hover,
  &.hover {
    background-color: var(${BUTTON_ICON_CSS_VARIABLES.PRIMARY.BG_COLOR_HOVER});
    color: var(${BUTTON_ICON_CSS_VARIABLES.PRIMARY.TEXT_COLOR_HOVER});
  }
  &:active,
  &.active {
    background-color: var(${BUTTON_ICON_CSS_VARIABLES.PRIMARY.BG_COLOR_ACTIVE});
    color: var(${BUTTON_ICON_CSS_VARIABLES.PRIMARY.TEXT_COLOR_ACTIVE});
  }
  &:disabled,
  &.disabled {
    background-color: var(${BUTTON_ICON_CSS_VARIABLES.PRIMARY.BG_COLOR_DISABLED});
    color: var(${BUTTON_ICON_CSS_VARIABLES.PRIMARY.TEXT_COLOR_DISABLED});
  }

  &.variant {

    &-white {
      background-color: var(${BUTTON_ICON_CSS_VARIABLES.PRIMARY_WHITE.BG_COLOR});
      color: var(${BUTTON_ICON_CSS_VARIABLES.PRIMARY_WHITE.TEXT_COLOR});

      &:hover,
      &.hover {
        background-color: var(${BUTTON_ICON_CSS_VARIABLES.PRIMARY_WHITE.BG_COLOR_HOVER});
        color: var(${BUTTON_ICON_CSS_VARIABLES.PRIMARY_WHITE.TEXT_COLOR_HOVER});
      }
      &:active,
      &.active {
        background-color: var(${BUTTON_ICON_CSS_VARIABLES.PRIMARY_WHITE.BG_COLOR_ACTIVE});
        color: var(${BUTTON_ICON_CSS_VARIABLES.PRIMARY_WHITE.TEXT_COLOR_ACTIVE});
      }
      &:disabled,
      &.disabled {
        background-color: var(${BUTTON_ICON_CSS_VARIABLES.PRIMARY_WHITE.BG_COLOR_DISABLED});
        color: var(${BUTTON_ICON_CSS_VARIABLES.PRIMARY_WHITE.TEXT_COLOR_DISABLED});
      }
    }
    &-red {
      background-color: var(${BUTTON_ICON_CSS_VARIABLES.PRIMARY_RED.BG_COLOR});
      color: var(${BUTTON_ICON_CSS_VARIABLES.PRIMARY_RED.TEXT_COLOR});

      &:hover,
      &.hover {
        background-color: var(${BUTTON_ICON_CSS_VARIABLES.PRIMARY_RED.BG_COLOR_HOVER});
        color: var(${BUTTON_ICON_CSS_VARIABLES.PRIMARY_RED.TEXT_COLOR_HOVER});
      }
      &:active,
      &.active {
        background-color: var(${BUTTON_ICON_CSS_VARIABLES.PRIMARY_RED.BG_COLOR_ACTIVE});
        color: var(${BUTTON_ICON_CSS_VARIABLES.PRIMARY_RED.TEXT_COLOR_ACTIVE});
      }
      &:disabled,
      &.disabled {
        background-color: var(${BUTTON_ICON_CSS_VARIABLES.PRIMARY_RED.BG_COLOR_DISABLED});
        color: var(${BUTTON_ICON_CSS_VARIABLES.PRIMARY_RED.TEXT_COLOR_DISABLED});
      }
    }

    &-outline {
      border-width: 2px;
      border-style: solid;
      border-color: var(${BUTTON_ICON_CSS_VARIABLES.PRIMARY_OUTLINE.BORDER_COLOR});
      background-color: var(${BUTTON_ICON_CSS_VARIABLES.PRIMARY_OUTLINE.BG_COLOR});
      color: var(${BUTTON_ICON_CSS_VARIABLES.PRIMARY_OUTLINE.TEXT_COLOR});

      &:hover,
      &.hover {
        border-color: var(${BUTTON_ICON_CSS_VARIABLES.PRIMARY_OUTLINE.BORDER_COLOR_HOVER});
        background-color: var(${BUTTON_ICON_CSS_VARIABLES.PRIMARY_OUTLINE.BG_COLOR_HOVER});
        color: var(${BUTTON_ICON_CSS_VARIABLES.PRIMARY_OUTLINE.TEXT_COLOR_HOVER});
      }
      &:active,
      &.active {
        border-color: var(${BUTTON_ICON_CSS_VARIABLES.PRIMARY_OUTLINE.BORDER_COLOR_ACTIVE});
        background-color: var(${BUTTON_ICON_CSS_VARIABLES.PRIMARY_OUTLINE.BG_COLOR_ACTIVE});
        color: var(${BUTTON_ICON_CSS_VARIABLES.PRIMARY_OUTLINE.TEXT_COLOR_ACTIVE});
      }
      &:disabled,
      &.disabled {
        border-color: var(${BUTTON_ICON_CSS_VARIABLES.PRIMARY_OUTLINE.BORDER_COLOR_DISABLED});
        background-color: var(${BUTTON_ICON_CSS_VARIABLES.PRIMARY_OUTLINE.BG_COLOR_DISABLED});
        color: var(${BUTTON_ICON_CSS_VARIABLES.PRIMARY_OUTLINE.TEXT_COLOR_DISABLED});
      }
    }

  }
`;

const SECONDARY_STYLES = `
  background-color: var(${BUTTON_ICON_CSS_VARIABLES.SECONDARY.BG_COLOR});
  color: var(${BUTTON_ICON_CSS_VARIABLES.SECONDARY.TEXT_COLOR});

  &:hover,
  &.hover {
    background-color: var(${BUTTON_ICON_CSS_VARIABLES.SECONDARY.BG_COLOR_HOVER});
    color: var(${BUTTON_ICON_CSS_VARIABLES.SECONDARY.TEXT_COLOR_HOVER});
  }
  &:active,
  &.active {
    background-color: var(${BUTTON_ICON_CSS_VARIABLES.SECONDARY.BG_COLOR_ACTIVE});
    color: var(${BUTTON_ICON_CSS_VARIABLES.SECONDARY.TEXT_COLOR_ACTIVE});
  }
  &:disabled,
  &.disabled {
    background-color: var(${BUTTON_ICON_CSS_VARIABLES.SECONDARY.BG_COLOR_DISABLED});
    color: var(${BUTTON_ICON_CSS_VARIABLES.SECONDARY.TEXT_COLOR_DISABLED});
  }

  &.variant {

    &-transparent {
      background-color: var(${BUTTON_ICON_CSS_VARIABLES.SECONDARY_TRANSPARENT.BG_COLOR});
      color: var(${BUTTON_ICON_CSS_VARIABLES.SECONDARY_TRANSPARENT.TEXT_COLOR});

      &:hover,
      &.hover {
        background-color: var(${BUTTON_ICON_CSS_VARIABLES.SECONDARY_TRANSPARENT.BG_COLOR_HOVER});
        color: var(${BUTTON_ICON_CSS_VARIABLES.SECONDARY_TRANSPARENT.TEXT_COLOR_HOVER});
      }
      &:active,
      &.active {
        background-color: var(${BUTTON_ICON_CSS_VARIABLES.SECONDARY_TRANSPARENT.BG_COLOR_ACTIVE});
        color: var(${BUTTON_ICON_CSS_VARIABLES.SECONDARY_TRANSPARENT.TEXT_COLOR_ACTIVE});
      }
      &:disabled,
      &.disabled {
        background-color: var(${BUTTON_ICON_CSS_VARIABLES.SECONDARY_TRANSPARENT.BG_COLOR_DISABLED});
        color: var(${BUTTON_ICON_CSS_VARIABLES.SECONDARY_TRANSPARENT.TEXT_COLOR_DISABLED});
      }
    }

    &-dark {
      background-color: var(${BUTTON_ICON_CSS_VARIABLES.SECONDARY_DARK.BG_COLOR});
      color: var(${BUTTON_ICON_CSS_VARIABLES.SECONDARY_DARK.TEXT_COLOR});

      &:hover,
      &.hover {
        background-color: var(${BUTTON_ICON_CSS_VARIABLES.SECONDARY_DARK.BG_COLOR_HOVER});
        color: var(${BUTTON_ICON_CSS_VARIABLES.SECONDARY_DARK.TEXT_COLOR_HOVER});
      }
      &:active,
      &.active {
        background-color: var(${BUTTON_ICON_CSS_VARIABLES.SECONDARY_DARK.BG_COLOR_ACTIVE});
        color: var(${BUTTON_ICON_CSS_VARIABLES.SECONDARY_DARK.TEXT_COLOR_ACTIVE});
      }
      &:disabled,
      &.disabled {
        background-color: var(${BUTTON_ICON_CSS_VARIABLES.SECONDARY_DARK.BG_COLOR_DISABLED});
        color: var(${BUTTON_ICON_CSS_VARIABLES.SECONDARY_DARK.TEXT_COLOR_DISABLED});
      }
    }

    &-outline {
      border-width: 2px;
      border-style: solid;
      border-color: var(${BUTTON_ICON_CSS_VARIABLES.SECONDARY_OUTLINE.BORDER_COLOR});
      background-color: var(${BUTTON_ICON_CSS_VARIABLES.SECONDARY_OUTLINE.BG_COLOR});
      color: var(${BUTTON_ICON_CSS_VARIABLES.SECONDARY_OUTLINE.TEXT_COLOR});

      &:hover,
      &.hover {
        border-color: var(${BUTTON_ICON_CSS_VARIABLES.SECONDARY_OUTLINE.BORDER_COLOR_HOVER});
        background-color: var(${BUTTON_ICON_CSS_VARIABLES.SECONDARY_OUTLINE.BG_COLOR_HOVER});
        color: var(${BUTTON_ICON_CSS_VARIABLES.SECONDARY_OUTLINE.TEXT_COLOR_HOVER});
      }
      &:active,
      &.active {
        border-color: var(${BUTTON_ICON_CSS_VARIABLES.SECONDARY_OUTLINE.BORDER_COLOR_ACTIVE});
        background-color: var(${BUTTON_ICON_CSS_VARIABLES.SECONDARY_OUTLINE.BG_COLOR_ACTIVE});
        color: var(${BUTTON_ICON_CSS_VARIABLES.SECONDARY_OUTLINE.TEXT_COLOR_ACTIVE});
      }
      &:disabled,
      &.disabled {
        border-color: var(${BUTTON_ICON_CSS_VARIABLES.SECONDARY_OUTLINE.BORDER_COLOR_DISABLED});
        background-color: var(${BUTTON_ICON_CSS_VARIABLES.SECONDARY_OUTLINE.BG_COLOR_DISABLED});
        color: var(${BUTTON_ICON_CSS_VARIABLES.SECONDARY_OUTLINE.TEXT_COLOR_DISABLED});
      }
    }

  }
`;

const THIRDPARTY_STYLES = `
  background-color: var(${BUTTON_ICON_CSS_VARIABLES.THIRD_PARTY.BG_COLOR});
  color: var(${BUTTON_ICON_CSS_VARIABLES.THIRD_PARTY.TEXT_COLOR});

  &:hover,
  &.hover {
    background-color: var(${BUTTON_ICON_CSS_VARIABLES.THIRD_PARTY.BG_COLOR_HOVER});
    color: var(${BUTTON_ICON_CSS_VARIABLES.THIRD_PARTY.TEXT_COLOR_HOVER});
  }
  &:active,
  &.active {
    background-color: var(${BUTTON_ICON_CSS_VARIABLES.THIRD_PARTY.BG_COLOR_ACTIVE});
    color: var(${BUTTON_ICON_CSS_VARIABLES.THIRD_PARTY.TEXT_COLOR_ACTIVE});
  }
  &:disabled,
  &.disabled {
    background-color: var(${BUTTON_ICON_CSS_VARIABLES.THIRD_PARTY.BG_COLOR_DISABLED});
    color: var(${BUTTON_ICON_CSS_VARIABLES.THIRD_PARTY.TEXT_COLOR_DISABLED});
  }
`;

const StyledButtonIcon = styled(BaseButton).withConfig({
  componentId: 'ButtonIcon-StyledButtonIcon',
} as any)<ButtonIconProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;

  &.shape-circle {
    border-radius: 50%;
  }

  &.shape-rounded-rect {
    border-radius: 8px;
  }

  &.size-x-large {
    font-size: 27px;
    min-width: 60px;
    width: 60px;
    height: 60px;
  }

  &.size-large {
    font-size: 20px;
    min-width: 40px;
    width: 40px;
    height: 40px;
  }

  &.size-medium {
    font-size: 16px;
    min-width: 32px;
    width: 32px;
    height: 32px;
  }

  &.size-small {
    font-size: 12px;
    min-width: 24px;
    width: 24px;
    height: 24px;
  }

  &.size-x-small {
    font-size: 8px;
    min-width: 16px;
    width: 16px;
    height: 16px;
  }

  &.type-primary {
    ${PRIMARY_STYLES}
  }

  &.type-secondary {
    ${SECONDARY_STYLES}
  }

  &.type-thirdparty {
    ${THIRDPARTY_STYLES}
  }

  &.variant-outline {
    border-width: 2px;
    border-style: solid;
    border-color: rgb(233 233 236);
    background-color: rgb(0 0 0 / 0%);
    color: rgb(36 41 56);
    &:hover {
      border-color: rgb(210 211 217);
      background-color: rgb(0 0 0 / 0%);
      color: rgb(36 41 56);
    }
  }
`;

export interface ButtonIconProps extends BaseButtonProps {
  shape?: 'circle' | 'rounded-rect';
  size?: 'x-large' | 'large' | 'medium' | 'small' | 'x-small';
  type?: 'default' | 'primary' | 'secondary' | 'thirdparty';
  variant?: 'normal' | 'transparent' | 'white' | 'dark' | 'outline' | 'red';
}

export const ButtonIcon = (props: ButtonIconProps) => {
  const {
    className,
    shape = 'circle',
    size = 'large',
    type = 'default',
    variant = 'normal',
    children,
    ...rest
  } = props;

  return (
    <StyledButtonIcon
      className={cl(`shape-${shape} size-${size} type-${type} variant-${variant}`, className)}
      {...rest}
    >
      {children}
    </StyledButtonIcon>
  );
};
